import React from 'react';
import styled from 'styled-components';
import {Button, Popconfirm, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import permissionRequired, {
  appendSlashSuffix,
} from '../../Utils/PermissionRequiredUtil';
import {formatDateStr} from '../../Utils/TimeUtil';
import AdminLineDivider from '../../Components/AdminLineDivider';

function AdminAnnualFeeCreate(props) {
  const [users] = useOutlet('users');
  const [user] = useOutlet('user');

  const [values, setValues] = React.useState({
    year: `${new Date().getFullYear() - 1911}`,
    state: [1],
    substate: ['4', '5', '6'],
  });
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [records, setRecords] = React.useState(null);

  return (
    <Wrapper>
      <div className="title-container">
        <h1 className="title">常年會費：年度開徵</h1>
      </div>
      <div className="container">
        <AdminLineDivider title="基本設定" />
        <GenericForm
          instance={values}
          schema={{
            title: '',
            type: 'object',
            required: ['year', 'state', 'substate'],
            properties: {
              year: {
                type: 'string',
                title: '開徵年度',
                default: values.year,
              },
              state: {
                type: 'integer',
                title: '會員狀態',
                default: values.state,
                enum: [1],
                enumNames: ['入會'],
                readOnly: true,
              },
              substate: {
                type: 'array',
                title: '會員次狀態',
                default: values.substate,
                uniqueItems: true,
                items: {
                  enum: ['4', '5', '6'],
                  enumNames: ['一般會員', '特別會員', '外國法事務律師'],
                },
              },
            },
          }}
          onChange={(formData) => {
            setValues(formData);
          }}
          renderCustomSubmitButton={(params) => {
            const {values} = params;

            return (
              <div className="actions">
                <Button
                  onClick={async () => {
                    AppActions.setLoading(true);
                    setDownloadUrl(null);

                    if (!values.year) {
                      AppActions.setLoading(false);
                      message.warning('請填寫開徵年度');
                      return;
                    }

                    if (
                      Array.isArray(values.state) &&
                      values.state.length === 0
                    ) {
                      AppActions.setLoading(false);
                      message.warning('請填寫會員狀態');
                      return;
                    }

                    if (
                      Array.isArray(values.substate) &&
                      values.substate.length === 0
                    ) {
                      AppActions.setLoading(false);
                      message.warning('請填寫會員次狀態');
                      return;
                    }

                    if (users.length === 0) {
                      AppActions.setLoading(false);
                      return;
                    }

                    try {
                      const limit = 500;
                      const substateTotals = {};
                      const substateFetchCounts = {};
                      const substatePromiseArray = [];
                      const seniorityPromiseArray = [];

                      const targetUsers = users
                        .filter((user) => user.state === values.state[0])
                        .filter((user) =>
                          values.substate.includes(user.substate),
                        );

                      message.info({
                        content: `總回傳數共 ${targetUsers.length} 筆，請耐心等候試算結果`,
                        duration: 0,
                      });

                      //step1: substate fetch
                      for (let i = 0; i < values.substate.length; i++) {
                        const substate = values.substate[i];

                        const total = [...targetUsers].filter(
                          (u) => u.substate === substate,
                        );

                        substateTotals[substate] = total.length;
                        substateFetchCounts[substate] = Math.ceil(
                          total.length / 500,
                        );

                        for (
                          let n = 0;
                          n < Math.ceil(total.length / limit);
                          n++
                        ) {
                          const promise = AppActionsEx.adminGetAnnualFeesCalc({
                            annual_year: values.year,
                            state: values.state[0],
                            substate: substate,
                            paging: {
                              offset: n * limit,
                              limit,
                            },
                          });

                          substatePromiseArray.push(promise);
                        }
                      }

                      const substateRespArray = await Promise.all(
                        substatePromiseArray,
                      );

                      const fetchCounts = Object.values(
                        substateFetchCounts,
                      ).reduce((acc, cur) => acc + cur, 0);

                      //step2: seniority fetch
                      for (let d = 0; d < fetchCounts; d++) {
                        const users_id = substateRespArray[d].map(
                          (r) => r.owner,
                        );
                        const promise = AppActionsEx.getUserSeniorities({
                          users_id,
                        });

                        seniorityPromiseArray.push(promise);
                      }

                      const senioritysRespArray = await Promise.all(
                        seniorityPromiseArray,
                      );

                      //step3: merge data
                      let records = [];
                      let seniorities = [];

                      for (let e = 0; e < fetchCounts; e++) {
                        records = [...records, ...substateRespArray[e]];
                        seniorities = [
                          ...seniorities,
                          ...senioritysRespArray[e],
                        ];
                      }

                      records = records.map((r) => {
                        const targetUser = users.find(
                          (user) => user.owner === r.owner,
                        );

                        //birthday
                        const birthday = targetUser.birthday
                          ? formatDateStr(new Date(targetUser.birthday))
                          : '';

                        //age
                        const age_timestamp =
                          new Date().getTime() - new Date(targetUser.birthday);
                        const age = `${parseInt(
                          age_timestamp / (365 * 24 * 60 * 60 * 1000),
                        )}歲`;

                        //seniority
                        const seniority_obj = seniorities.find(
                          (user) => user.owner === r.owner,
                        );
                        const seniority = seniority_obj.seniority;

                        //join start date and end date
                        let join_start_date = '';
                        let join_end_date = '';

                        if (
                          r.inouts &&
                          Array.isArray(r.inouts) &&
                          r.inouts.length > 0
                        ) {
                          const _inouts = [...r.inouts]
                            .filter((r) => !!r.EFF_DATE)
                            .map((r) => ({
                              ...r,
                              EFF_DATE: r.EFF_DATE.split('T')[0],
                            }))
                            .sort(
                              (a, b) =>
                                new Date(b.EFF_DATE).getTime() -
                                new Date(a.EFF_DATE).getTime(),
                            );

                          join_start_date =
                            _inouts.length > 0 ? _inouts[0].EFF_DATE : '';

                          join_end_date =
                            _inouts.length > 0
                              ? _inouts[0].EXPR_DATE !== '9999-12-31T00:00:00'
                                ? _inouts[0].EXPR_DATE
                                  ? _inouts[0].EXPR_DATE.split('T')[0]
                                  : ''
                                : ''
                              : '';
                        }

                        return {
                          ...r,
                          name: targetUser.name,
                          state: getUserStateLabel(targetUser.state),
                          substate: getUserSubstateLabel(targetUser.substate),
                          id_card_number: targetUser.id_card_number,
                          birthday,
                          age,
                          seniority,
                          join_start_date,
                          join_end_date,
                        };
                      });
                      setRecords(records);

                      message.destroy();

                      const link = await generateExcelFile(records);
                      if (link) {
                        setDownloadUrl(link);
                      }
                    } catch (err) {
                      console.warn(err);
                      message.destroy();
                      message.error('發生錯誤');
                    } finally {
                      AppActions.setLoading(false);
                    }
                  }}>
                  產生試算結果
                </Button>
                {downloadUrl && (
                  <div>
                    <Button
                      style={{marginLeft: 10, marginRight: 10}}
                      type="link"
                      download={`${values.year}年度開徵試算結果.xlsx`}
                      href={downloadUrl}
                      target="_blank">
                      下載試算結果
                    </Button>
                    <Popconfirm
                      title={`確認開徵${values.year}年度嗎？`}
                      okText="確認"
                      cancelText="取消"
                      onCancel={() => 0}
                      onConfirm={async () => {
                        const prompt = window.prompt(
                          `${values.year}年度開徵，總計創建 ${records.length} 筆常年會費訂單。請輸入您的姓名驗證權限：`,
                        );
                        const canWrite = permissionRequired(
                          `${appendSlashSuffix(props.path)}_write`,
                        );

                        if (!prompt) {
                          return;
                        }

                        if (prompt !== user.name || !canWrite) {
                          message.error('驗證未通過，無法開徵');
                          return;
                        }

                        if (prompt === user.name && canWrite) {
                          try {
                            message.info({
                              content: `正在進行 ${values.year} 年度開徵，請勿關閉或重新整理頁面`,
                              duration: 0,
                            });
                            AppActions.setLoading(true);
                            await AppActionsEx.adminCreateAnnualFees({
                              annual_year: values.year,
                            });

                            message.destroy();
                            message.success('開徵完成');
                          } catch (err) {
                            console.warn(err);
                            message.destroy();
                            message.error('發生錯誤');
                          } finally {
                            AppActions.setLoading(false);
                          }
                        }
                      }}>
                      <Button type="primary">確認開徵</Button>
                    </Popconfirm>
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
    </Wrapper>
  );
}

async function generateExcelFile(records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([
      [
        '編號',
        '姓名',
        '身分證字號',
        '狀態',
        '次狀態',
        '生日',
        '年齡',
        '年資',
        '入會日期',
        '退會日期',
        '年度',
        '計費起月',
        '計費迄月',
        '金額',
      ],
      ...records.map((r, i) => {
        return [
          `${i + 1}`,
          `${r.name}`,
          `${r.id_card_number}`,
          `${getUserStateLabel(r.state)}`,
          `${getUserSubstateLabel(r.substate)}`,
          `${r.birthday}`,
          `${r.age}`,
          `${r.seniority}`,
          `${r.join_start_date}`,
          `${r.join_end_date}`,
          `${r.annual_year}`,
          `${r.start_month}`,
          `${r.end_month}`,
          `${r.total}`,
        ];
      }),
    ]);

    window.XLSX.utils.book_append_sheet(wb, ws, '開徵試算');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    message.success('成功創建下載連結');
    return objUrl;
  } catch (err) {
    console.warn('generateExcelFile ex', err);
  } finally {
    AppActions.setLoading(false);
  }
}

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px;

  & > .title-container {
    & > .title {
      margin-bottom: 10px;
      font-size: 32px;
    }
  }

  & > .container {
  }

  & .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

export default withPageEntry(AdminAnnualFeeCreate);
